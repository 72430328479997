.hero {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  position: relative;
  background: url('./images/bg.png'),
    linear-gradient(180deg, rgba(6, 5, 66, 0.96) 0%, rgba(5, 4, 55, 0.96) 100%);
  backdrop-filter: blur(400px);
  background-repeat: no-repeat;
  background-size: cover;
  /* position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: -1; */
}

.hold {
  padding: 0px 72px;
  width: 100%;
  padding-right: 0;
}
.hero-content {
  display: flex;
  color: white;
  font-size: calc(10px + 2vmin);
  font-weight: 300;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 80px 0 68px 47px;
  /* background: #010105 url(./images/logo-back.png); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  align-items: center;
  justify-content: space-between;
  overflow-x: hidden;
}

.hero-img-container {
  flex: 1;
}

.hero-image {
  position: relative;
  min-height: 100%;
  /* overflow: visible; */
  width: 100%;
}
.hero-image .img {
  /* position: absolute;
  top: -28vh;
  width: 100%;
  top: -32vh;
  right: 15%;
  width: 40vh; */
  /* width: 90%; */
  margin-right: -100px;
}

.hero-image .edge {
  position: absolute;
}

.hero-image .edge.left {
  left: -55px;
  top: 54px;
}

.hero-image .edge.right {
  right: 20px;
  top: -104px;
}

.hero-image .sm_img {
  position: absolute;
  bottom: -120px;
  left: -10%;
  width: 162.05px;
  transform: translatey(0px);
  animation: float 6s ease-in-out infinite;
  z-index: 10;
}
.hero-image .img1 {
  top: -40vh;
  width: 100%;
}
.hero-image .img2 {
  top: -28vh;
  width: 100%;
}

.hero-image .float {
  position: absolute;
  width: 100%;
  bottom: -95px;
  left: -130px;
}
.nav {
  display: flex;
  align-items: center;
  padding: 46px 120px;
  width: 100%;
}

.nav .logo {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #ffffff;
}
.hero-content-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.hero-content-container .pill {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  background: #f0f0ff;
  border-radius: 24px;
  font-size: 12px;
  line-height: 24px;
  color: #1d1d1d;
  max-width: 330px;
}

.hero-content-container .pill .pill-inner {
  padding: 6px 12px;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
  background: #0059f7;
  border-radius: 20px;
  margin-right: 11px;
}

.hero-content-container .pill img {
  margin-left: 8px;
  margin-right: 11px;
  width: 16px;
  height: 16px;
}

.hero-title {
  font-weight: bold;
  font-size: 56px;
  line-height: 82px;
  max-width: 571px;
  margin: 40px 0 0 0;
}
.hero-subtitle {
  margin: 18px 0 0 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  max-width: 551px;
}

.hero-subtitle span {
  font-weight: bold;
}

.hero-inputs {
  display: flex;
  /* align-items: center; */
  width: 100%;
  max-width: 560px;
  margin-top: 25px;
  flex-direction: column;
}

.hero-inputs input {
  flex: 1;
  padding: 16px 32px;
  border-radius: 8px;
  border: none;
  outline: none;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  border-radius: 8px;
  background: rgba(97, 96, 153, 0.61);
  border: none;
}

.hero-inputs input::placeholder {
  color: #c4c4c4;
}

.hero-inputs button {
  background: #0059f7;
  border-radius: 8px;
  border: none;
  outline: none;
  padding: 14px 30px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 28px;
}

.hero-inputs-groups {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

.hero-live {
  display: inline;
  align-items: center;
  margin-top: 27px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2em;
}
.hero-live span {
  background: #eb840c;
  border-radius: 4px;
  padding: 6px 10px;
  letter-spacing: 0.1em;
  margin-left: 8px;
  animation: blink 1.2s ease-in-out infinite;
  animation: name duration timing-function delay iteration-count direction
    fill-mode;
}

.hero-foot {
  position: absolute;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 0.12em;
  color: #0d61f8;
  display: flex;
  align-items: center;
  bottom: -13px;
}

.hero-foot img {
  width: 32px;
  height: 21.33px;
  margin: 0 12px;
}

.hero-title .hero-grad {
  /* background: linear-gradient(
    180deg,
    #eb840c 0%,
    rgba(255, 122, 0, 0.91) 79.17%
  ); */

  /* background-size: 100%; */
  /* background-repeat: repeat; */
  /* background-position: 0px 15px; */
  /* background-clip: text; */
  /* -webkit-background-clip: text; */
  /* -webkit-text-fill-color: transparent; */
  /* -moz-background-clip: text; */
  /* -moz-text-fill-color: transparent; */
  color: rgba(255, 122, 0, 0.91);
}

.features {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  background: #ffffff;
  color: #1d1d1d;
  padding: 30px 120px 20px 120px;
}

.features h2 {
  font-size: 36px;
  line-height: 57px;
  margin-bottom: 10px;
}

.features .sub {
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  max-width: 687px;
  margin-bottom: 55px;
  color: #1d1d1d;
}

.features .cards {
  /* background: #020633; */
  border-radius: 20px;
  flex: 1;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  row-gap: 34px;
  column-gap: 19px;
  /* padding: 70px 44px; */
}

.features .cards .card {
  display: flex;
  flex-direction: column;
  text-align: center;
  border: 0.7px solid #e5e5e5;
  border-radius: 12px;
  padding: 30px;
  align-items: center;
}

.features .cards .card .card-icon {
  height: 66px;
  width: 66px;
  margin-bottom: 4px;
  border-radius: 50%;
  background: #ffefdd;
  display: flex;
  align-items: center;
  justify-content: center;
}

.features .cards .card .card-icon.green {
  background: #e8ffe4;
}

.features .cards .card .card-icon.pink {
  background: #ffdef8;
}

.features .cards .card .card-icon.purple {
  background: #e5e4ff;
}
..features .cards .card card-icon.red {
  background: #ffe8f3;
}

.features .cards .card .card-icon.blue {
  background: #e1f4ff;
}

.features .cards .card h3 {
  font-weight: 550;
  font-size: 24px;
  line-height: 26px;
  margin: 16px 0;
  color: #1d1d1d;
}
.features .cards .card p {
  margin: 0;
  font-size: 16px;
  line-height: 28px;
  color: #333333;
  /* max-width: 313px; */
}

.cases {
  /* min-height: 100vh; */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  background: #ffffff;
  color: #1d1d1d;
  padding: 50px 120px 20px 120px;
}

.cases h2 {
  font-size: 44px;
  line-height: 57px;
  margin-bottom: 24px;
}

.case-header {
  margin-bottom: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.case-header h2 {
  font-size: 36px;
  line-height: 57px;
  margin: 0;
}
.case-header h2 span {
  color: #eb840c;
}
.case-header p {
  font-size: 28px;
  font-weight: 500;
  color: #555;
  margin: 0;
  text-align: center;
}

.cases .case-box {
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 550px;
}

.cases .case-box .left-box {
  flex: 1;
  /* background: red; */
  height: 550px;
  position: relative;
  /* overflow: hidden; */
}

.cases .case-box .left-box .hang {
  position: absolute;
  left: 0%;
  bottom: -10%;
}

.cases .case-box .right-box {
  flex: 1;
  height: 550px;
  overflow-y: auto;
  padding-left: 120px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-top: 40px;
}

.cases .case-box .right-box::-webkit-scrollbar {
  display: none;
}

.cases .case-box .right-box .card {
  display: flex;
  flex-direction: column;
  text-align: left;
  /* background: #fffcf8; */
  /* backdrop-filter: blur(4px); */
  /* border-radius: 5px; */
  /* padding: 50px 20px 30px; */

  /* border-left: 4px solid #ea19e1; */
  margin-bottom: 57px;
}

.cases .case-box .right-box .card.blue .card-icon {
  background: #1e0ceb;
}
.cases .case-box .right-box .card.gold .card-icon {
  background: #c87b07;
}

.cases .case-box .right-box .card.pink .card-icon {
  background: #c807a9;
}

.cases .case-box .right-box .card.orange .card-icon {
  background: #c4063f;
}
.cases .case-box .right-box .card.bluer .card-icon {
  background: #2f68fa;
}

.cases .case-box .right-box .card .card-icon {
  height: 44px;
  width: 44px;
  margin-bottom: 30px;
  border-radius: 50%;
  display: flex;
  position: relative;
  background: #039724;
}

.cases .case-box .right-box .card .card-icon img {
  position: absolute;
  top: -5px;
  left: 50%;
  height: 28px;
  width: 28px;
}

.cases .case-box .right-box .card h3 {
  font-weight: bold;
  font-size: 28px;
  line-height: 36px;
  margin: 16px 0;
  color: #000000;
}
.cases .case-box .right-box .card p {
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  /* max-width: 313px; */
}

.story {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f7f7fe;
  padding: 0px 86px 100px;
}
.story .story-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.story h2 {
  font-weight: bold;
  font-size: 36px;
  line-height: 64px;
  text-align: center;
  color: #1d1d1d;
  max-width: 628px;
}

.story h2 span {
  color: #ec7d3a;
}
.story .cards {
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 23px;
  text-align: left;
}
.story .cards .card {
  display: flex;
  flex-direction: column;
  padding: 44px 20px;
}
.story .cards .card .card-icon {
  height: 60px;
  width: 60px;
  margin-bottom: 8px;
  border-radius: 50%;
  background: #fff1e0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.story .cards .card .card-icon.pink {
  background: #fee2ec;
}
.story .cards .card .card-icon.green {
  background: #e2ffe3;
}
.story .cards .card .card-icon.blue {
  background: #e0e9ff;
}
.story .cards .card h3 {
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: #1d1d1d;
  margin: 16px 0;
}

.story .cards .card p {
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  color: #666666;
}

.story button {
  background: #0059f7;
  border-radius: 8px;
  padding: 14px 54px;
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  border: none;
  outline: none;
  margin-top: 57px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.story button img {
  margin-left: 12px;
  width: 16px;
  height: 16px;
}
.story .story-head {
  font-weight: 600;
  font-size: 18px;
  line-height: 31px;
  align-items: center;
  display: flex;
  justify-content: center;
  color: #0d61f8;
}

.story .story-head img {
  margin-left: 8px;
}

.footer {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #020633;
  padding: 105px 180px;
  color: #ffffff;
  padding-top: 187px;
  /* position: sticky;
  position: -webkit-sticky;
  bottom: 0;
  z-index: -2; */
}
.footer.no-top {
  padding: 100px 180px;
}

.footer .box {
  background: #0059f7;
  border-radius: 20px;
  min-height: 420px;
  padding: 68px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.footer .box h2 {
  font-weight: bold;
  font-size: 44px;
  line-height: 57px;
  margin: 20px 0;
}
.footer .box p {
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  margin: 0;
  max-width: 563px;
}
.footer-inputs {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 635px;
  margin-top: 20px;
  justify-content: center;
}

.footer-inputs input {
  flex: 1;
  padding: 16px 32px;
  background: rgba(200, 200, 231, 0.61);
  border-radius: 8px;
  border: none;
  outline: none;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
}

.footer-inputs a {
  text-decoration: none;
  width: auto;
}

.footer-inputs input::placeholder {
  color: #ffffff;
}

.footer-inputs button {
  background: #ffffff;
  border-radius: 8px;
  border: none;
  outline: none;
  padding: 14px 30px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #0059f7;
  cursor: pointer;
  margin-left: 8px;
  display: flex;
  align-items: center;
}

button img {
  margin-left: 12px;
}
.footer-list {
  display: flex;
  align-items: center;
  margin-top: 24px;
  margin-left: 62px;
}

.footer-list .list-item {
  display: flex;
  align-items: center;
  margin-right: 62px;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.footer-list .list-item span {
  margin-left: 8px;
}

.footer .box .box-head {
  height: 60px;
  width: 60px;
  position: absolute;
  top: -30px;
  left: calc(50% - 30px);
}

.footer .box .box-head .around {
  position: absolute;
  width: 161.39px;
  height: 158.33px;
  top: calc(50% - 79.67px);
  left: calc(50% - 80.67px);
  animation: rotate infinite 20s linear;
}

.footer .box .edge {
  position: absolute;
  width: 150px;
  height: 150px;
}

.edge.top-right {
  right: -90px;
  top: -63px;
}

.edge.bottom-left {
  bottom: -75px;
  left: -65px;
}
.footer .logo {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: center;
  align-self: center;
  margin-top: 63px;
}

.footer .address {
  padding-top: 10px;
  text-align: center;
}
.logo img {
  /* margin-right: 12px; */
}

.footer .socials {
  margin-top: 44px;
  display: flex;
  align-items: center;
  align-self: center;
}

.footer .socials a {
  text-decoration: none;
  margin: 0 8px;
}

.footer .socials a:active {
  transform: scale(0.98);
}

.footer .copyright {
  margin-top: 40px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}
button:active {
  transform: scale(0.98);
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* @keyframes rotate1 {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
} */

.human1 {
  bottom: 15%;
  left: 10%;
  width: 140px;
  height: 140px;
}

.human2 {
  bottom: 12%;
  right: 5%;
  width: 150px;
  height: 150px;
}

.human3 {
  top: 27%;
  right: 8%;
  width: 150px;
  height: 150px;
}

.human4 {
  top: 15%;
  left: 15%;
  width: 150px;
  height: 150px;
}

.human1,
.human2,
.human3,
.human4 {
  position: absolute;
  /* animation: rotate 5s infinite linear; */
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.41);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.modal-content {
  width: 90%;
  max-width: 788px;
  background: #ffffff;
  border-radius: 20px;
  height: 620px;
  padding: 48px 36px 62px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.modal-head {
  font-weight: bold;
  font-size: 36px;
  line-height: 47px;
  color: #212121;
  margin: 32px 0;
}

.modal-number-text {
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #1d1d1d;
  display: flex;
  align-items: center;
  margin: 9px 0;
}

.modal-number-text span {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
  padding: 4px 24px;
  background: #eb840c;
  border-radius: 4px;
  margin: 0 12px;
}

.modal-text {
  font-weight: 500;
  font-size: 18px;
  line-height: 36px;
  text-align: center;
  color: #333333;
  max-width: 452px;
  margin: 7px 0;
}

.modal-button {
  margin-top: 32px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  padding: 14px 111px;
  background: #0059f7;
  border-radius: 8px;
  border: none;
  cursor: pointer;
}

.modal-close {
  position: absolute;
  top: 28px;
  right: 36px;
  width: 24px;
  height: 24px;
  background: url(./images/close.svg) no-repeat center;
  background-size: cover;
  cursor: pointer;
  border: none;
}

.no-desktop {
  display: none;
}

.unsubscribe {
  padding: 20px;
  text-align: center;
  margin: auto 0;
}

.unsubscribe h1,
p {
  color: #ffffff;
}

.one-input-box {
  padding: 60px 0;
}

.one-input-box .hero-inputs-groups {
  grid-template-columns: 1fr !important;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  30% {
    opacity: 0;
  }
  60%,
  100% {
    opacity: 1;
  }
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}
