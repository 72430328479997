@media screen and (max-width: 768px) {
  .hold {
    padding: 0;
  }
  .no-mobile {
    display: none;
  }
  .no-desktop {
    display: block;
  }
  .hero-content {
    flex-direction: column;
    border-radius: 0;
    padding: 61px 23px;
  }

  .features .cards {
    grid-template-columns: auto;
  }

  .story .cards {
    grid-template-columns: auto;
  }
  .footer-inputs {
    flex-direction: column;
  }

  .hero-inputs {
    flex-direction: column;
  }

  .hero {
    min-height: 100vh;
    height: auto;
    position: relative;
  }

  .nav {
    padding: 33px 24px;
  }

  .nav .logo {
    font-size: 13.7571px;
    line-height: 18px;
  }
  .nav img {
    width: 97px;
    height: 42px;
  }

  .hero-content-container .pill {
    padding: 4px 6px;
    font-weight: 500;
    font-size: 4vw;
    line-height: 22px;
    width: 100%;
    align-self: flex-start;
    max-width: 100%;
  }
  .hero-content-container .pill .pill-inner {
    font-size: 2.8vw;
    line-height: 19px;
    margin-right: 6px;
  }

  .hero-content-container .pill img {
    width: 12px;
    height: 12px;
    margin-left: 2px;
    margin-right: 0;
  }
  .hero-title {
    font-size: 36px;
    line-height: 50px;
  }
  .hero-subtitle {
    font-size: 14px;
    line-height: 24px;
    margin-top: 22px;
  }

  .hero-inputs input {
    width: 100%;
  }
  .hero-inputs button {
    width: 100%;
    margin-top: 16px;
    justify-content: center;
    margin-left: 0;
  }
  .hero-live {
    font-size: 12px;
    line-height: 28px;
    margin-top: 14px;
  }
  .hero-foot {
    display: none;
  }

  .features {
    padding: 75px 25px 30px;
  }

  .features h2 {
    font-size: 28px;
    line-height: 36px;
  }
  .features .sub {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 68px;
  }
  .features .cards {
    /* padding: 81px 25px; */
    row-gap: 21px;
  }

  .features .cards .card h3 {
    font-size: 20px;
    line-height: 26px;
    margin: 12px 0;
  }
  .features .cards .card p {
    font-size: 12px;
    line-height: 20px;
  }
  .features .cards .card .card-icon {
    width: 52px;
    height: 52px;
    margin-bottom: 12px;
  }
  .features .cards .card .card-icon img {
    width: 31.52px;
    height: 31.52px;
  }

  .cases {
    padding: 75px 25px 30px;
  }
  .case-header {
    margin-bottom: 0px;
  }
  .case-header h2 {
    font-size: 28px;
    line-height: 36px;
  }

  .case-header p {
    font-size: 18px;
  }
  .cases .case-box .right-box {
    grid-template-columns: auto;
  }
  .cases h2 {
    font-size: 28px;
    line-height: 36px;
  }

  .cases .case-box {
    flex-direction: column;
    height: auto;
    margin-top: 40px;
  }
  .cases .case-box .left-box {
    width: 100%;
  }

  .cases .case-box .left-box img {
    width: 100%;
  }

  .cases .case-box .left-box .hang {
    bottom: -90px;
  }

  .cases .case-box .right-box {
    height: auto;
    padding-left: 0;
    padding-top: 80px;
  }

  .cases .case-box .right-box .card h3 {
    font-size: 20px;
    line-height: 26px;
    margin: 12px 0;
  }
  .cases .case-box .right-box .card p {
    font-size: 12px;
    line-height: 20px;
  }
  .cases .case-box .right-box .card .card-icon {
    width: 52px;
    height: 52px;
    margin-bottom: 12px;
  }
  .cases .case-box .right-box .card .card-icon img {
    width: 31.52px;
    height: 31.52px;
  }

  .story {
    padding: 84px 25px 5px;
  }
  .story .story-head {
    font-size: 14px;
    line-height: 19px;
  }
  .story h2 {
    font-size: 24px;
    line-height: 36px;
  }
  .story .story-bottom {
    padding: 50px 0;
  }
  .footer {
    padding: 158px 25px 41px;
    overflow: hidden;
    position: relative;
  }

  .footer.no-top {
    padding: 30px 25px;
  }

  .footer .box {
    padding: 100px 26px 74px;
  }

  .footer .box h2 {
    font-size: 20px;
    line-height: 26px;
  }
  .footer .box p {
    font-size: 12px;
    line-height: 20px;
  }

  .footer-inputs input {
    width: 100%;
  }
  .footer-inputs button {
    width: 100%;
    margin-top: 16px;
    justify-content: center;
    margin-left: 0;
  }
  .footer-list {
    width: 100%;
    margin: 0;
    margin-top: 24px;
    justify-content: space-between;
  }

  .footer-list .list-item {
    font-size: 12px;
    line-height: 16px;
    margin-right: 1px;
  }
  .footer-list .list-item img {
    width: 16px;
    height: 16px;
  }
  .footer-list .list-item span {
    margin-left: 4px;
  }
  .edge {
    width: 102px !important;
    height: 102px !important;
  }
  .edge.top-right {
    top: -55px;
    right: -55px;
  }

  .edge.bottom-left {
    bottom: -55px;
    left: -55px;
  }
  .hero-image {
    width: auto;
    height: auto;
    min-height: auto;
    display: flex;
    align-items: center;
    justify-self: center;
  }

  .hero-image .img {
    /* position: relative; */
    /* min-width: 200px;
    max-width: 374.76px; */
    width: 100%;
    align-self: center;
    /* top: 0;
    margin: 0 auto;
    right: 0; */
    margin: auto;
  }
  .hero-image .sm_img {
    display: none;
  }
  .hero-img-container {
    /* padding: 63px 0 123px; */
    padding-top: 81px;
  }

  .modal-content {
    padding: 45px 14px;
    height: auto;
  }

  .modal-content img {
    width: 114px;
    height: 114px;
  }

  .modal-head {
    font-size: 28px;
    line-height: 36px;
    margin: 36px 0 26px;
  }

  .modal-number-text {
    font-size: 14px;
    line-height: 26px;
  }

  .modal-number-text span {
    font-size: 18px;
    line-height: 36px;
    padding: 2px 22px;
  }

  .modal-text {
    font-size: 14px;
    line-height: 28px;
  }

  .modal-button {
    font-size: 16px;
    line-height: 24px;
    width: 100%;
    padding: 14px 0;
  }

  .modal-close {
    right: 15px;
    width: 22px;
  }
  .hero-inputs-groups {
    grid-template-columns: 1fr;
  }
  .footer-inputs a {
    width: 100%;
  }

  .footer h2 {
    text-align: center;
  }
  .unsubscribe {
    padding: 20px 0;
    text-align: left;
    margin: auto 0;
  }
  .unsubscribe h1 {
    font-size: 20px;
  }

  .unsubscribe p {
    font-size: 12px;
  }

  .one-input-box {
    padding: 30px 0;
  }

  .hero-image .edge.left {
    left: -40px;
    top: 94px;
  }

  .hero-image .edge.right {
    right: 20px;
    top: -64px;
  }
}
